
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class supplementary_materials extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  t_all_data=[]

t_total= 0;
t_search_data = {
  results_name: '',
  group_leader_name: '',
  status: ''

}

rule_t_search_data = {

}

t_edit_dialog = {
  open: false,
  title: '上传补充材料'
}

t_edit_data = {
  id: 0,
  file: [] as any[],
  submit_content: ''

}

rule_t_edit_data = {

}

status_1018 = new Map<any, string>([[1, '已提交'], [2, '通过审核'], [3, '审核失败']])

t_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    results_name: this.t_search_data.results_name,
    group_leader_name: this.t_search_data.group_leader_name,
    status: this.t_search_data.status

  }
  Api.http_supplementary_materialst0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_all_data = res.data.data
      this.t_total = res.data.total
    }
  })
}
t_edit_init(row) {

  Api.http_supplementary_materialstget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit_data.id = row.id;
      this.t_edit_data.file = res.data.file;
      this.t_edit_data.submit_content = res.data.submit_content;
      this.t_edit_dialog.open = true;

    }
  })

}
t_edit_cancle() {
  this.t_edit_dialog.open = false;
}
t_edit_ok() {
  this.$refs.ref_t_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit_data.id,
        file: this.t_edit_data.file,
        submit_content: this.t_edit_data.submit_content

      }
      Api.http_supplementary_materialst5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_init() {
  this.t_get_all_data(0, 10);

}
created() {
  this.t_init();

}

}
